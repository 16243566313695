import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PropertyCard from "gatsby-theme-starberry-lomondgroup/src/components/PropertyCard/PropertyCard";
import Slider from "react-slick";

import "gatsby-theme-starberry-lomondgroup/src/components/Features/Features.scss";

import {FeaturedPropertiesOffice} from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query"

const Features = ({office_crm_id, tab_list}) => {
    const[department, setDepartment] = useState("residential")
    const[search_type, setSearchType] = useState("sales")
    const[propertyList, setPropertiesList] = useState("sales")

    const{loading, error, data} = FeaturedPropertiesOffice(department, search_type, office_crm_id);
    //const{loading, error, data} = FeaturedProperties(department, search_type);

    //console.log("properties ==>", data?.properties, department, search_type)

    
    // useEffect(()=>{
    //     if(tab_list?.length > 0){
    //         var split_tab_value = tab_list[0].add_tab.split("_")
    //         setDepartment(split_tab_value[0])
    //         setSearchType(split_tab_value[1])
    //     }
    // },[tab_list])

    // const handleChange = (event) =>{
    //     var get_tab_key = event.target.attributes.getNamedItem("data-rr-ui-event-key").value;
    //     var split_tab_value = get_tab_key.split("_")
    //     setDepartment(split_tab_value[0])
    //     setSearchType(split_tab_value[1])

    //     //console.log("tab_key", event.target.attributes.getNamedItem("data-rr-ui-event-key").value)
    // }
    
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots:true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },

        ],
    };

    //console.log("office_crm_id", office_crm_id);


    return (
        <React.Fragment>
            {(data?.sale_properties?.length > 0 || data?.let_properties?.length > 0) && (
            <section className={`features `}>
                <Container>
                    <Row>
                        <Col>
                        <>
                            <h2>Featured Properties</h2>
                            <div className="tab-wrapper">                                
                                <Tabs
                                    defaultActiveKey={data?.sale_properties?.length > 0 ? "residential_sales" : "residential_lettings"}
                                    id="uncontrolled-tab-example"
                                    //onClick={handleChange}
                                    >
                                    {data?.sale_properties?.length > 0 && 
                                        <Tab eventKey={"residential_sales"} title="Property for Sale" >                                            
                                            <Slider {...settings}>
                                                {data?.sale_properties?.map((item, index) => (
                                                    <PropertyCard key={index} FeaturesList={item} />
                                                ))}
                                            </Slider>
                                            
                                        </Tab>
                                    }

                                    {data?.let_properties?.length > 0 && 
                                        <Tab eventKey={"residential_lettings"} title="Property to Rent" >                                            
                                            <Slider {...settings}>
                                                {data?.let_properties?.map((item, index) => (
                                                    <PropertyCard key={index} FeaturesList={item} />
                                                ))}
                                            </Slider>
                                        </Tab>
                                    }                                        
                                </Tabs>                             
                            </div>
                        </>
                            
                        </Col>
                    </Row>
                </Container>
            </section>
            )}
        </React.Fragment>
    );
};
export default Features;
